















































import { defineComponent,ref ,onMounted} from "@vue/composition-api";
import QueryTable from "@/common/ListQuerytorTs/index"
import dayjs from "dayjs";
import { $get } from "@/http/axios";
/**
 * 转换成替换掉数值里的逗号 number
 * @param {string} val 
 */
const computedNumber = (val)=>Number(val.replace(',',''))
export default defineComponent({
  name: "ManagementInfo",
  setup(props, ctx) {
    let items = [
      {
        name: "出库记录",
        count: 0,
        key: "num1",
        type: 1,
        querytor: new QueryTable(),

        columns: [
          { label: "序号", type: "index" },
          { label: "日期", prop: "deliveryDate", isDate: true },
          { label: "产品名称", prop: "deliveryProductName" },
          { label: "摘要", prop: "deliveryProductAbstract" },
          { label: "计量单位", prop: "deliveryMetering" },
          { label: "出库数量", prop: "deliveryCnt" },
          { label: "单价(元)", prop: "deliveryPrice" },
          { label: "工业销售产值", prop: "deliverySalesValue" },
        ],
      },
      {
        name: "入库单",
        key: "num2",
        count: 0,
        type: 1,

        querytor: new QueryTable(),
        columns: [
          { label: "序号", type: "index" },
          { label: "日期", prop: "putInDate" },
          { label: "产品名称", prop: "putInProductName" },
          { label: "摘要", prop: "putInProductAbstract" },
          { label: "计量单位", prop: "putInMetering" },
          {
            label: "入仓数量",
            prop: "putInCnt",
            map: (row) => computedNumber(row.putInCnt),
          },
        ],
      },
      {
        name: "工业产值概况",
        key: "num3",
        count: 0,
        type: 1,

        querytor: new QueryTable(),
        columns: [
          { label: "序号", type: "index" },
          { label: "日期", prop: "outputDate" },
          { label: "产品名称", prop: "outputProductName" },
          { label: "摘要", prop: "outputProductAbstract" },
          { label: "计量单位", prop: "outputMetering" },
          { label: "月初库存量", prop: "outputStartMonthInventory" },
          { label: "入仓数量", prop: "outputInCnt" },
          { label: "出仓数量", prop: "outputOutCnt" },
          { label: "月末库存率", prop: "outputEndMonthInventory" },
          { label: "平均出厂价(不含税)", prop: "outputOutAvgPrice" },
          { label: "工业总产值(元)", prop: "outputTotalValue" },
          { label: "工业销售产值(元)", prop: "outputSalesValue" },
        ],
      },
      {
        name: "工业生产消费概况",
        key: "num4",
        count: 0,
        type: 1,

        querytor: new QueryTable(),
        columns: [
          { label: "计量单位", prop: "consumeMeteringv" },
          { label: "吨标准煤", prop: "consumeEnergyAmount" },
          { label: "万千瓦时", prop: "consumePowerAmount" },
          { label: "累计", prop: "consumePowerTotalAmount" },
          { label: "金额(元)", prop: "consumePowerMoney" },
          { label: "累计", prop: "consumePowerTotalMoney" },

          { label: "吨", prop: "consumeWaterAmount" },
          { label: "累计", prop: "consumeWaterTotalAmount" },
          { label: "金额(元)", prop: "consumeWaterMoney" },
          { label: "累计", prop: "consumeWaterTotalMoney" },
          { label: "吨", prop: "consumeCoalAmount" },
          { label: "累计", prop: "consumeCoalTotalAmount" },
          { label: "金额(元)", prop: "consumeCoalMoney" },
          { label: "累计", prop: "consumeCoalMoney" },
          { label: "备注", prop: "consumeRemarks" },
        ],
      },
      {
        name: "能耗概况",
        key: "num5",
        count: 0,
        type: 1,

        querytor: new QueryTable(),
        columns: [
          { label: "年", prop: "energyYear" },
          { label: "月", prop: "energyMonth" },
          { label: "日", prop: "energyDay" },
          { label: "年初库存量", prop: "energyStartYearInventory" },
          { label: "实物量", prop: "energyPhysicalQuantity" },
          { label: "金额(千元)", prop: "energyMoney" },
          { label: "合计", prop: "energyTotalAmount" },
          { label: "工业生产消费", prop: "energyIndustryConsume" },
          { label: "用于原材料", prop: "energyRawMaterialConsume" },
          { label: "非工业生产消费", prop: "energyNonIndustryConsume" },
          { label: "合计值:运输工具消费", prop: "energyTotalTransConsume" },
          { label: "期末库存量", prop: "energyEndTermInventory" },
        ],
      },
      {
        name: "委托产销概况",
        key: "num6",
        count: 0,
        type: 1,

        querytor: new QueryTable(),
        columns: [
          { label: "日期", prop: "entrustMonth" },
          { label: "产品名称", prop: "entrustProductName" },
          { label: "计量单位", prop: "entrustMetering" },
          { label: "月初库存量", prop: "entrustStartMonthInventory" },
          { label: "本期生产量", prop: "entrustCurProduction" },
          { label: "本期销售量", prop: "entrustCurSalesVolume" },
          { label: "月末库存量", prop: "entrustEndMonthInventory" },
          {
            label: "本期工业总产值(千元)",
            prop: "entrustIndustryOutputValue",
          },
          {
            label: "本期工业销售产值(千元)",
            prop: "entrustIndustrySalesValue",
          },
        ],
      },
      {
        name: "产销存",
        key: "num7",
        count: 0,
        type: 1,

        querytor: new QueryTable(),
        columns: [
          { label: "月份", prop: "inventoryMonth" },
          { label: "产品名称", prop: "inventoryProductName" },
          { label: "计量单位", prop: "inventoryMetering" },
          { label: "年初库存量", prop: "inventoryStartYear" },
          { label: "本期生产量", prop: "inventoryCurProduction" },
          { label: "本期销售量", prop: "inventoryCurSalesVolume" },
          { label: "企业自用及其他", prop: "inventorySelfUse" },
          { label: "期末库存量", prop: "inventoryEndTerm" },
        ],
      },
      {
        name: "资产负债表",
        key: "num8",
        count: 0,
        type: 1,
        querytor: new QueryTable(),
        columns: [
          { label: "序号", type: "index" },
          { label: "资产", prop: "assetsName" },
          { label: "年份", prop: "assetsYear" },
          { label: "月份", prop: "assetsMonth" },
          { label: "期末余额", prop: "assetsEndTermBalance" },
          { label: "年初余额", prop: "assetsStartYearBalance" },
        ],
      },
      {
        name: "利润表",
        key: "num9",
        count: 0,
        type: 1,
        querytor: new QueryTable(),
        columns: [
          { label: "序号", type: "index" },
          { label: "项目", prop: "profitProjectName" },
          { label: "年份", prop: "profitYear" },
          { label: "月份", prop: "profitMonth" },
          { label: "本月利润总额", prop: "profitCurMonthTotalAmount" },
          { label: "本年利润总额", prop: "profitCurYearTotalAmount" },
        ],
      },
      {
        name: "产品信息",
        type: 2,
        key: "num1",
        count: 0,
        querytor: new QueryTable(),
        columns: [
          { label: "产品编码", prop: "boxCpbm" },
          { label: "客方货号", prop: "boxKfhh" },
          { label: "客户简称", prop: "boxKhjc" },
          { label: "产品类别", prop: "boxCplb" },
          { label: "盒型名称", prop: "boxHxmc" },
          { label: "纸质", prop: "boxZz" },
        ],
      },
      {
        name: "现金流量表",
        type: 2,
        key: "num2",
        count: 0,
        querytor: new QueryTable(),
        columns: [
          { label: "序号", type: "index" },
          { label: "项目", prop: "cashXm" },
          { label: "行次", prop: "cashHc" },
          { label: "本年累计金额(元)", prop: "cashBnljje" },
          { label: "本月金额(元)", prop: "cashByje" },
        ],
      },
      {
        name: "设备信息",
        type: 2,
        key: "num3",
        count: 0,
        querytor: new QueryTable(),
        columns: [
          { label: "固定资料编号", prop: "equipmentGdzlbh" },
          { label: "设备名称", prop: "equipmentSbmc" },
          { label: "规格", prop: "equipmentGg" },
          { label: "位置", prop: "equipmentWz" },
          { label: "状态", prop: "equipmentZt" },
          { label: "工序", prop: "equipmentGx" },
          { label: "产能", prop: "equipmentCn" },
        ],
      },
      {
        name: "人力信息",
        type: 2,
        key: "num4",
        count: 0,
        querytor: new QueryTable(),
        columns: [
          { label: "工号", prop: "humanGh" },
          { label: "姓名", prop: "humanXm" },
          { label: "性别", prop: "humanXb" },
          { label: "部门名称", prop: "humanBmmc" },
          { label: "岗位", prop: "humanGw" },
          { label: "入职日期", prop: "humanRzrq" },
          { label: "在职状态", prop: "humanZzzt" },
        ],
      },
      // {
      //   name: "纸板类产品信息",
      //   type: 2,
      //   key: "num5",
      //   count: 0,
      //   querytor: new QueryTable(),
      //   columns: [
      //     { label: "编号", prop: "cardboardBh" },
      //     { label: "品名", prop: "cardboardPm" },
      //     { label: "产品代码", prop: "cardboardCpdm" },
      //     { label: "层数", prop: "cardboardCs" },
      //     { label: "数/楞型", prop: "cardboardSLx" },
      //     { label: "理论耐破", prop: "cardboardLlnp" },
      //     { label: "边压", prop: "cardboardBianya" },
      //     { label: "水分", prop: "cardboardSf" },
      //     { label: "厚度", prop: "cardboardHd" },
      //     { label: "重量", prop: "cardboardZl" },
      //   ],
      // },
      {
        name: "原辅料采购领料进出存汇总表",
        type: 2,
        key: "num6",
        count: 0,
        querytor: new QueryTable(),
        columns: [
          { label: "类型", prop: "materialsLx" },
          {
            label: "年份-月份",
            prop: "materialsYear/materialsMonth",
            map: (row) => `${row.materialsYear}-${row.materialsMonth}`,
          },
          { label: "科目名称", prop: "materialsKmmc" },
          { label: "计量单位", prop: "materialsJldw" },
          { label: "期初数量", prop: "materialsQcsl" },
          { label: "期初金额(元)", prop: "materialsQsje" },
          {
            label: "入库数量",
            prop: "materialsRksl",
            map: (row) => computedNumber(row.materialsRksl),
          },
          { label: "入库金额(元)", prop: "materialsRkje" },
          { label: "出库数量", prop: "materialsCksl" },
          { label: "出库金额(元)", prop: "materialsCkje" },
          {
            label: "期末数量",
            prop: "materialsQmsl",
            map: (row) => computedNumber(row.materialsQmsl),
          },
          { label: "期末金额(元)", prop: "materialsQmje" },
        ],
      },
      {
        name: "维修保养记录",
        type: 2,

        key: "num7",
        count: 0,
        querytor: new QueryTable(),
        columns: [
          { label: "维修单号", prop: "repairWxdh" },
          { label: "维修日期", prop: "repairWxrq" },
          { label: "维修保养类型", prop: "repairWxbylx" },
          { label: "设备名称", prop: "repairSbmc" },
          { label: "设备类别名称", prop: "repairSblbmc" },
          { label: "工作内容", prop: "repairGznr" },
          { label: "检验人姓名", prop: "repairJyrxm" },
        ],
      },
      {
        name: "产品进出存汇总表",
        type: 2,
        key: "num8",
        count: 0,
        querytor: new QueryTable(),
        columns: [
          { label: "年份", prop: "inoutYear" },
          { label: "月份", prop: "inoutMonth" },
          { label: "货物名称", prop: "inoutMonth" },
          { label: "初期数量", prop: "inoutQcsl" },
          { label: "初期金额(元)", prop: "inoutQcje" },
          { label: "入库数量", prop: "inoutRksl" },
          { label: "入库金额(元)", prop: "inoutRkje" },
          { label: "出库数量", prop: "inoutCksl" },
          { label: "出库金额(元)", prop: "inoutCkje" },
          { label: "期末数量", prop: "inoutQmsl" },
          { label: "期末金额(元)", prop: "inoutQmje" },
        ],
      },
    ];
    const activeNames = ref([]);
    async function handleCollapaseChange(index) {
      let item = this.items[index];
      console.log("当前", index, item);
      if (item && item.querytor) {
        item.querytor.query();
      }
    }
    function renderTable({row,column,$index}){
      console.log("表格渲染",{row,column})
      if(column.type === 'index') return $index + 1
       if(!row[column.prop]){
        return "-"
      }
      // if(column.isNumber){
      //   return computedNumber()
      // }
      if(column.map && column.map instanceof Function){
        return column.map(row)
      }
     
      if(column.label.indexOf("日期")!==-1){
        return dayjs(row[column.prop]).format("YYYY-MM-DD")
      }
      if(column.label.indexOf("元")!==-1){
        return (computedNumber(row[column.prop])).toFixed(2)
      }
      if(column.label.indexOf("数量")!==-1){
        return (computedNumber(row[column.prop])).toFixed(0)
      }
  
      return  row[column.prop]
    }


    async function initQuerytor() {
      let socialCode = this.socialCode;
      items = items.map((item) => {
        let manageType = +Array.from(item.key).pop();
        item.querytor = new QueryTable( {
            api:'/wztc/operatearchives/queryOperateArchivesManageInfoBySocialCodeListNew',
            condition:{ socialCode, manageType, tableType: item.type },
        });
        return item;
      });
    }

    /** 获取概览信息 */
    async function  loadOverviewData() {
      let socialCode = `91330303145246896A`;
      let {result:data}  = await $get(`/wztc/operatearchives/queryCountByType`,{socialCode});
      console.log("数量概览信息返回", data);
      data =  Object.keys(data).reduce((obj,key)=>{
            let type = Array.from(key).pop(); //取最后一位数字 作为类型

            for(let k in data[key]){
                obj[`${type}_${k}`] = data[key][k] 
            }
            return obj;
        },{})
      console.log("数量概览信处理后", data);
      items = items.map((item) => {
        item.count = data[`${item.type}_${item.key}`] || 0; // tableType1[item.key];
        /**
         * 当 type为1 时  只显示 后端返回的
         * 当 type为2 时 都显示（包括值为0 的）
         */
        // item.show = item.type === 1 ? !!data[`${item.type}_${item.key}`] : true;
        return item;
      });
    return;
      let overviewData = await this.$api.queryCountByType(this.socialCode);
      console.log("数量概览信息返回", overviewData);

      this.items = this.items.map((item) => {
        item.count = overviewData[`${item.type}_${item.key}`] || 0; // tableType1[item.key];
        /**
         * 当 type为1 时  只显示 后端返回的
         * 当 type为2 时 都显示（包括值为0 的）
         */
        item.show =
          item.type === 1 ? !!overviewData[`${item.type}_${item.key}`] : true;
        return item;
      });
    }
    onMounted(()=>{
        loadOverviewData();
    })


    return {
        handleCollapaseChange,renderTable,
        items,activeNames
    }

  },
});
