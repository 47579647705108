


















































import { defineComponent, onMounted, ref } from "@vue/composition-api";
import ManagementInfo from "./infos/management.vue"
import BusinessInfo from "./infos/business.vue"
type DICT = {[key:number]:string}
export default defineComponent({
    name: "SpaceResource",
    components: { ManagementInfo ,BusinessInfo},
    setup(props,ctx){
        const tabs = [
            {name:"基础信息",value:"1"},
            {name:"工商信息",value:"2"},
            {name:"经营信息",value:"3"},
            {name:"运营信息",value:"4"},
            {name:"信用信息",value:"5"},
            {name:"能耗信息",value:"6"},
            {name:"公积金",value:"7"},
            {name:"社保",value:"8"},
            {name:"政策兑换",value:"9"},
            {name:"税收",value:"10"},
            {name:"企业驾驶舱",value:"11"},
        ]
        const infos = [
            {name:'法定代表人',value:'杨春林'},
            {name:'经营状态',value:'开业'},
            {name:'注册资本',value:'5,000万(元)'},
            {name:'实缴资本',value:'-'},
            {name:'曾用名',value:'-'},
            {name:'所属行业',value:'文教、工美、体育和娱乐用品制造业'},
            {name:'统一社会信用代码',value:'91330303145246896A'},
            {name:'纳税人识别号',value:'91330303145246896A'},
            {name:'工商注册号',value:'330303000066400'},
            {name:'组织机构代码',value:'14524689-6'},
            {name:'登记机关',value:'温州市市场监督管理局瓯江口新区分局'},
            {name:'成立日期',value:'1995-7-16 16:00:00'},
            {name:'企业类型',value:'有限责任公司(自然人投资或控股)'},
            {name:'营业期限',value:'1995-07-17 至 9999-09-09'},
            {name:'行政区划',value:'浙江省温州市龙湾区'},
            {name:'核准日期',value:'2021-3-21 16:00:00'},
            {name:'注册地址',value:'浙江省温州瓯江口产业集聚区瓯帆路288号'},
            {name:'经营范围',value:'笔、文具、玩具的生产、销售、技术服务；技术进出口、材料进出口。（依法须经批准的项目，经相关部门批准后方可开展经营活动）'},
        ]
        const active = ref("2")

       
        return {tabs,active,infos}
    }
})
