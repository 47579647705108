



























import { defineComponent, ref, onMounted } from "@vue/composition-api";

/**
 * 转换成替换掉数值里的逗号 number
 * @param {string} val
 */
const computedNumber = (val) => Number(val.replace(",", ""));
export default defineComponent({
  name: "BusinessInfo",
  setup(props, ctx) {
    const items = [
      {
        name: "股东信息",
        columns: [
          { label: "序号" },
          { label: "发起人/股东" },
          { label: "持股比例" },
          { label: "认缴出资额" },
          { label: "实际出资额" },
        ],
        data: [],
      },
      {
        name: "主要成员",
        columns: [{ label: "序号" }, { label: "姓名" }, { label: "职务" }],
        data: [],
      },
      {
        name: "对外投资",
        columns: [
          { label: "序号" },
          { label: "被投资企业" },
          { label: "被投资企业法人" },
          { label: "成立日期" },
          { label: "投资占比" },
          { label: "认缴金额" },
          { label: "状态" },
        ],
        data: [],
      },
      {
        name: "变更记录",
        columns: [
          { label: "序号" },
          { label: "变更日期" },
          { label: "变更项目" },
          { label: "变更前" },
          { label: "变更后" },
        ],
        data: [],
      },
      {
        name: "分支机构",
        columns: [
          { label: "序号" },
          { label: "企业名称" },
          { label: "负责人" },
          { label: "成立日期" },
          { label: "状态" },
        ],
        data: [],
      },
    ];
    const activeNames = ref([]);
    const handleCollapaseChange = () => {};

    return {
      items,
      activeNames,
      handleCollapaseChange,
    };
  },
});
